<!--
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-09 23:17:50
-->
<template>
    <a-spin :spinning="loading" style="max-height: none">
        <div class="login-bg">
            <a-card title="賬戶註冊" class="border-raduis box-shadow" style="width: 400px">
                <template #extra>
          <span @click="push({ name: 'login' })"
          >已有賬戶？<a href="javascript:;"> 登入</a></span
          >
                </template>

                <a-form ref="formRef" :model="formState" :rules="rules" :wrapper-col="wrapperCol">
                    <a-form-item label="請選擇用戶類別(選擇後將不可修改)" name="store_type_id">
                        <a-select v-model:value="formState.store_type_id" class="border-raduis">
                            <a-select-option
                                    v-for="item in storeTypeArr"
                                    :key="item.id"
                                    :value="item.id + ''"
                            >{{ item.name }}
                            </a-select-option
                            >
                        </a-select>
                    </a-form-item>

                    <a-form-item label="手提電話或電郵" name="account">
                        <a-input-search
                                class="border-raduis"
                                v-model:value="formState.account"
                                placeholder="請輸入手提電話或電郵"
                                size="large"
                                @search="getCodeFn"
                        >
                            <template #enterButton>
                                <a-button :disabled="countStatus" type="primary">獲取驗證碼<span v-show="countStatus"
                                                                                            class="margin-left-xs"> {{ countDown }}s</span>
                                </a-button>
                            </template>
                        </a-input-search>
                    </a-form-item>

                    <a-form-item label="驗證碼" name="code">
                        <a-input
                                size="large"
                                v-model:value="formState.code"
                                placeholder="請輸入驗證碼"
                        />
                    </a-form-item>

                    <a-form-item :wrapper-col="{ span: 24 }">
                        <a-button
                                type="primary"
                                shape="round"
                                size="large"
                                style="width: 100%"
                                @click="onSubmit"
                        >註冊
                        </a-button>
                    </a-form-item>
                </a-form>
            </a-card>
        </div>
    </a-spin>
</template>
<script  >
    import {defineComponent, reactive, ref, toRaw, nextTick,inject} from "vue";
    import {storeType, getCode, register} from "@/common/http/api";
    import {useRouter} from "vue-router";
    import {notification} from "ant-design-vue";
    import {setValue} from "@/common/util/storage";

    export default defineComponent({
        setup() {
            const { field } = inject("globalConfig");
            const {push, replace} = useRouter();
            const loading = ref(false);
            const formRef = ref();
            const rememberSetting = ref(0);
            const storeTypeArr = ref([]);
            const countStatus = ref(false);
            const countSeconds = 60;
            const countDown = ref(countSeconds);
            // 渲染後
            nextTick(() => {
                getStoreType();
            });

            // 獲取商戶類型
            const getStoreType = async () => {
                const result = await storeType({page: 1, size: 50});
                storeTypeArr.value = result.data;
                console.log("商戶類型", storeTypeArr.value);
            };

            // 表單數據
            const formState = reactive({
                store_type_id: "",
                account: "",
                code: "",
            });

            // 表單規則
            const rules = {
                store_type_id: [{required: true, message: "請選擇商戶類型", trigger: "blur"}],
                account: [{required: true, message: "請輸入Email或電話號碼", trigger: "blur"}],
                code: [{required: true, message: "請輸入驗證碼", trigger: "blur"}],
            };
            // 獲取code
            const getCodeFn = async () => {
                if (countStatus.value) return;
                await getCode({account: formState.account, type: 22});
                countStatus.value = true;
                notification.success({
                    message: "驗證碼發送成功！",
                    duration: 2,
                });
                const setTimer = setInterval(() => {
                    countDown.value--;
                    if (countDown.value === 0) {
                        clearInterval(setTimer);
                        countStatus.value = false;
                        countDown.value = countSeconds;
                    }
                }, 1000)
            };

            // 提交
            const onSubmit = async () => {
                try {
                    loading.value = true;
                    await formRef.value.validate(); // 调用表单验证
                    const registerRes = await register({...toRaw(formState)});

                    // console.log('注册返回',registerRes);

                    setValue(field.token, registerRes.token, registerRes.expire_time); // 本地缓存token
                    setValue(field.type, registerRes.store_type, registerRes.expire_time); // 本地缓存商戶類型

                    notification.success({
                        message: "註冊成功！",
                        duration: 2,
                    });
                    replace({path: "/EssentialInfo"});
                    return;
                } catch (error) {
                    loading.value = false;
                    console.log("提交错误：", error);
                }
            };

            return {
                formRef,
                wrapperCol: {span: 24},
                formState,
                rules,
                onSubmit,
                loading,
                rememberSetting,
                push,
                storeTypeArr,
                countDown,
                countStatus,
                getCodeFn,
            };
        },
    });
</script>

<style lang="less">
    .login-bg {
        height: 100vh;
        width: 100%;
        background-image: url("~@/assets/img/login-bg.png");
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
